/*=============================================
=           10. Call To Action Css             =
=============================================*/

.cta-bg-image_one {
    background-color: #454545;
    background-image: url(../images/patterns/mitech-call-to-action-image-global.webp);
    background-repeat: no-repeat;
    background-position: top 35% right -68px;
}
.cta-bg-image_two {
    background-color: #f6f2ed;
    background-image: url(../images/patterns/mitech-home-appointment-call-to-action-bg-image.webp);
    background-repeat: no-repeat;
    background-position: top right;
}

.cta-content {
    @media #{$tablet-device , $large-mobile}{
        text-align: center;
        margin-bottom: 10px;
    }
}

.cta-button-group {
    &--one{
        & .btn-one {
            color: $theme-color--default;
            margin: 10px;
            &:hover {
                background: $theme-color--default;
                color: $white;
            }
        }
        & .btn-two {
            margin: 10px;
            &:hover {
                background: $theme-color--default;
                color: $white;
            }
        }
    }
    &--two{
        & .btn-one {
            background: $theme-color--default;
            color: $white;
            margin: 10px;
        }
        & .btn-two {
            margin: 10px;
        }
    }
}



.talk-message-box {
    max-width: 630px;
    margin: auto;
    border-radius: 5px;
    background-color: #e7edfa;
    padding: 28px 35px;
    position: relative;
    .message-icon{
        font-size: 24px;
        width: 64px;
        height: 64px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 64px;
        background: #fff;
        box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
        position: absolute;
        top: -35px;
        left: -35px;
        color: $theme-color--default;
        @media #{$large-mobile} {
            top: -43px;
            left: 5px;
        }
    }
}
.talk-message-box-content {
    display: flex;
    justify-content: space-between;
    .heading {
        max-width: 350px;
    }
    @media #{$large-mobile} {
        .heading {
            max-width: 280px;
        }
    }
    @media #{$small-mobile} {
        flex-direction: column;
        .heading {
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
}




/*=====  End of cta   ======*/
