/*=====================================
=      40. About Us pages Css           =
=======================================*/
.about-us-bg {
    background-image: url(../images/bg/contact-us-01-hero-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
} 
.banner-space {
    padding-top: 195px;
    padding-bottom: 204px;
    @media #{$desktop-device}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media #{$tablet-device}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.vision-space {
    padding-top: 85px;
    padding-bottom: 195px;
    @media #{$desktop-device}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media #{$tablet-device}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.requirements-list {
    width: 600px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 100%;
    float: right;
}
.resources-left-box {
    max-width: 600px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    float: right;
    @media #{$desktop-device}{
        margin: 0 auto;
        float: none;
    }
    @media #{$tablet-device}{
        text-align: center;
        margin: 0 auto;
        float: none;
    }
    @media #{$large-mobile}{
        margin: 0 auto;
        margin-bottom: 0px;
        float: none;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
        text-align: center;
    }
}
.resources-inner {
    & .sub-title {
        color: #ababab;
    }
}

.about-resources-wrapper {
    padding: 40px 0;
    background-color: #f8f8f8;
    background-image: url(../images/bg/mitech-slider-cybersecurity-global-image.webp);
    background-repeat: no-repeat;
    background-position: center right 40px;
}

.delivering-optimal-wrap {
    .marker {
        &::before {
            content: '\f00c';
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            font-size: 24px;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: $font-awesome;
            font-weight: 600;
            color: $theme-color--two;
        }
    }
    .list-item {
        padding-left: 40px;
        margin-top: 40px;
        position: relative;
        & .title-wrap {
            .title {
                color: $theme-color--default;
                margin-bottom: 15px;
            }
        }
    }
}
.modern-number-01 {
    @media #{$tablet-device , $large-mobile}{
        margin-bottom: 20px;
    }
    &.number-two{
        & h2 {
          max-width: 330px; 
        }
        span.mark-text {
            color: $theme-color--default;
        }
    }
}
.contact-button {
    & a {
        color: $theme-color--default;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.45;
        border-bottom: 1px solid #ddd;
    }
}
.about-button {
    text-align: right;
    & a {
        min-width: 170px; 
    }
}
.gallery-warp {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    
    &:nth-child(4n+1) {
        grid-column: span 8;
    }
}

.gallery-grid {
    display: block;
    width: 100%;
    height: 100%;
    
    & .single-gallery__thum {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-height: 420px;
    }
    
    &:nth-child(4n+1) {
        -ms-grid-column: span 8;
        grid-column: span 8;
        @media #{$tablet-device , $large-mobile}{
          grid-column: span 6;  
        }
        @media #{$small-mobile}{
          grid-column: span 12;  
        }
    }
    &:nth-child(4n+2) {
        -ms-grid-column: span 4;
        grid-column: span 4;
        @media #{$tablet-device , $large-mobile}{
          grid-column: span 6;  
        }
        @media #{$small-mobile}{
          grid-column: span 12;  
        }
    }
    &:nth-child(4n+3) {
        -ms-grid-column: span 6;
        grid-column: span 6;
        @media #{$small-mobile}{
          grid-column: span 12;  
        }
    }
    &:nth-child(4n+4) {
        -ms-grid-column: span 6;
        grid-column: span 6;
        @media #{$small-mobile}{
          grid-column: span 12;  
        }
    }
}

.single-gallery {
    overflow: hidden;
    display: block;
    border-radius: 5px;
    &__item {
        position: relative; 
        height: 100%;
        & .ht-overlay {
            background-color: $theme-color--default;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: $transition--default;
        }
        &  .overlay-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: auto;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: $transition--default;
            & i {
                font-size: 24px;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &:hover {
            & .ht-overlay , & .overlay-icon {
                opacity: .9;
                visibility: visible;
            }
        }
    }
}
.bg-item-images {
    position: relative;
    display: flex;
    align-items: stretch;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    &::before {
        content: '';
        display: block;
        padding-top: 67%;
    }
}