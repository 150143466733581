/*======================================
=         12. Counters Css              =
=======================================*/
.fun-fact-grid-4 {
    border-right: 1px solid #ededed;
    display: flex;
    flex-wrap: wrap;
    & .grid-item {
        width: 25%;
        padding-top: 100px;
        padding-bottom: 100px;
        position: relative;
        @media #{$tablet-device , $large-mobile}{
            padding-top: 60px;
            padding-bottom: 60px;
        }
        
         
        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            bottom: 0;
            background: #ededed;
            width: 1px;
        }
        &::after {
            position: absolute;
            content: '';
            top: -1px;
            left: 0;
            right: 0;
            background: #ededed;
            height: 1px;
        }
        @media #{$tablet-device}{
            width: 50%;
        }
        @media #{$large-mobile}{
            width: 100%;
        }
        
    }
}
.fun-fact {
    &--one {
        & .fun-fact__title {
            color: $theme-color--black;
            font-weight: 500;
            margin-bottom: 10px;
        }
        & .fun-fact__count {
            font-size: 64px;
            font-weight: 600;
            line-height: 1.41;
            color: $theme-color--default;
        }
        & .fun-fact__text {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $theme-color--two;
        }
    }
    &--two {
        margin-bottom: 30px;
        & .fun-fact__count {
            font-size: 56px;
            color: $white;
            margin-bottom: 20px;
            line-height: 1;
            font-weight: 700;
        }
        & .fun-fact__text {
            text-transform: uppercase;
            color: rgba(255,255,255,0.7);
            font-size: 16px;
            letter-spacing: 2px;
        }
    }
    &--three {
        position: relative;
        margin-bottom: 40px;
        @media #{$desktop-device}{
            margin-bottom: 30px;
        }
        @media #{$tablet-device}{
            margin-bottom: 0px;
            margin-top: 30px;
        }
        @media #{$large-mobile}{
            margin-bottom: 0px;
            margin-top: 30px;
        }
        & .fun-fact__count {
            color: $theme-color--default;
            margin-bottom: 19px;
            font-size: 56px;
            font-weight: 400;
            line-height: 1;
        }
        & .fun-fact__text {
            font-size: 14px;
            text-transform: uppercase;
            color: #7e7e7e;
        }
    }
    &--four {
        position: relative;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        @media #{$desktop-device}{
            margin-bottom: 30px;
        }
        @media #{$tablet-device}{
            margin-bottom: 30px;
        }
        @media #{$large-mobile}{
            margin-bottom: 30px;
        }
        .icon {
            border-radius: 10px;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background: #fff;
            font-size: 30px;
            text-align: center;
            margin-right: 20px;
            box-shadow: 0 0 15px rgba(51, 51, 51, 0.05);
            color: #002FA6;
        }
        .fun-fact-text {
            text-align: left;
        }
        & .fun-fact__count {
            color: $black;
            margin-bottom: 10px;
            font-size: 34px;
            font-weight: 600;
            line-height: 1;
        }
        & .fun-fact__text {
            font-size: 14px;
            text-transform: uppercase;
            color: #333;
            letter-spacing: 2px;
        }
    }
    &--five {
        margin-bottom: 40px;
        & .fun-fact__count {
            font-size: 46px;
            color: $white;
            margin-bottom: 20px;
            line-height: 1;
            font-weight: 700;
        }
        & .fun-fact__text {
            text-transform: uppercase;
            color: rgba(255,255,255,0.7);
            font-size: 16px;
            letter-spacing: 2px;
        }
    }
    
}

.modern-number-01 {
    & h2 {
        position: relative;
        font-size: 12px;
        line-height: 2em;
        letter-spacing: 2px;
        color: #ababab;
        text-transform: uppercase;
        display: flex;
        align-items: flex-end;
    }
    & span.mark-text {
        font-size: 180px;
        line-height: .8;
        font-weight: 900;
        margin: 0 5px -5px -5px;
        color: #d2a98e;
        @media #{$large-mobile}{
          font-size: 140px;
        }
    }
    & h3 {
        margin-top: 25px;
        font-size: 38px;
        @media #{$desktop-device}{
          font-size: 30px; 
        }
        @media #{$tablet-device}{
          font-size: 28px;
        }
        @media #{$large-mobile}{
          font-size: 24px;
        }
    }
}
.cybersecurity-about-text {
    & .text {
        font-size: 18px;
        margin-bottom: 20px;
    }
}
.managed-it-fun-fact-wrapper {
    & .fun-fact-grid-4 .grid-item {
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;
        &::after {
            display: none;
        }
        @media #{$tablet-device}{
            &::after {
              display: inherit;
            }
        }
        @media #{$large-mobile}{
            &::after {
              display: inherit;
            }
        }
    }
}