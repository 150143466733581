
/*=============================================
=             01. Default Css                 =
=============================================*/

*, *::after, *::before {
    box-sizing: border-box;
  }
html, body {
    height: 100%;
}
// html {
//     direction:rtl;
// }
body{
  line-height: $body-line-height;
  font-size: $body-font-size;
  font-style: normal;
  font-weight: $body-font-weight;
  visibility: visible;
  font-family: $font-family-name;
  color: $body-text-color;
  position: relative;
  background-color: $body-bg-color;
  &.no-overflow{
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
    color: $heading-text-color;
    font-family: $heading-font-family-name;
    font-weight: $heading-font-weight;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.3;
}
h1 {
    font-size: 56px;
    @media #{$desktop-device}{
      font-size: 46px; 
    }
    @media #{$tablet-device}{
      font-size: 40px;
    }
    @media #{$large-mobile}{
      font-size: 34px;
    }
  }
h2 {
    font-size: 48px;
    @media #{$desktop-device}{
      font-size: 36px; 
    }
    @media #{$tablet-device}{
      font-size: 36px;
    }
    @media #{$large-mobile}{
      font-size: 30px;
    }
  }
h3 {
    font-size: 40px;
    @media #{$desktop-device}{
      font-size: 32px; 
    }
    @media #{$tablet-device}{
      font-size: 28px;
    }
    @media #{$large-mobile}{
      font-size: 25px;
    }
}
h4 {
    font-size: 34px;
    @media #{$desktop-device}{
      font-size: 30px; 
    }
    @media #{$tablet-device}{
      font-size: 28px;
    }
    @media #{$large-mobile}{
      font-size: 24px;
    }
}
h5 {
    font-size: 24px;
    @media #{$large-mobile}{
      font-size: 20px;
    }
}
h6 {
    font-size: 18px;
}

p:last-child {
    margin-bottom: 0;
}
a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
a, button, img, input {
  transition: $transition--default;
}
*:focus {
  outline: none !important;
}
a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
.btn.focus, .btn:focus {
    outline: none;
    box-shadow: none;
}
a:hover {
  text-decoration: none;
  color: $theme-color--default;
}
button, input[type="submit"] {
  cursor: pointer;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.form-messege ,
.form-messege-2  {
    &.success {
        color: green;
    }
    &.error {
        color: red;
    }
}
.mark, mark {
    padding: 0 0;
    background-color: transparent;
}
.font-weight--bold {
    font-weight: 800;
}  
.font-weight--reguler {
    font-weight: 500;
} 
.font-weight--normal {
    font-weight: 400;
}  
.font-weight--light {
    font-weight: 300;
} 

/*--
    Default Class
*/


.text-color-primary {
    color: $theme-color--default;
}
.text-color-secondary {
    color: $heading-text-two;
}
.text-black {
    color: $theme-color--black !important;
}
.bg-white {
    background: $white;
}
.bg-black {
    background: #333;
}
.bg-gray {
    background: #F8F8F8;
}
.bg-gray-2 {
    background: #F6FAFE;
}
.bg-gray-3 {
    background: #f6f2ed;
}
.bg-theme-default {
    background: $theme-color--default;
}
.theme-bg-secondary {
    background: $theme-color--two;;
}
.bg-theme-three {
    background: $theme-color--three;
}
.sub-heading {
    color: $theme-color-sub--heading;
}
.black-bg {
    background: $black;
}
.border-radus-5 {
    border-radius: 5px;
}
.text-green {
    color: $theme-color--two;
}
.bg-gradient {
   background: -webkit-linear-gradient(top,#FFF 0,#F5F5F5 100%); 
}


/*-- Tab Content & Pane Fix --*/
select {
    padding: 3px 20px;
    height: 56px;
    max-width: 100%;
    width: 100%;
    outline: none;
    border: 1px solid #f8f8f8;
    border-radius: 5px;
    background: #f8f8f8 url("../images/icons/selector-icon.webp") no-repeat center right 20px;
    background-color: rgb(248, 248, 248);
    -moz-appearance: none;
    -webkit-appearance: none;
    &:focus {
        background: #f8f8f8 url("../images/icons/selector-icon.webp") no-repeat center right 20px !important;
    }
}
.fixed-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
/*------- Defauld Class --------*/
.text-black {
    color: #333;
}
.box-shadow-top {
   box-shadow:  0 10px 15px rgba(0, 0, 0, 0.05)
}
.border{
    border: 1px solid #ededed !important;
}
.border-top{
    border-top: 1px solid #ededed !important;
}
.border-right{
    border-right: 1px solid #ededed !important;
}
.border-bottom{
    border-bottom: 1px solid #ededed !important;
}
.border-left{
    border-left: 1px solid #ededed !important;
}
.border-top-dash {
    border-top: 1px dashed #ddd !important;
}
.border-bottom-dash {
    border-bottom: 1px dashed #ddd !important;
}
.border-top-thick {
    border-top: 2px solid #ededed !important;
}
.border-bottom-thick {
    border-bottom: 2px solid #ededed !important;
}
.border-top-drak{
    border-top: 1px solid rgba(255,255,255,0.2) !important;
}
.border-bottom-drak{
    border-bottom: 1px solid rgba(255,255,255,0.2) !important;
}
.section-title {
    & mark {
        color: $theme-color--green;
        padding: .0em;
        background-color: transparent;
    }
}
.section-sub-title {
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
}
.section-under-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.40;
    color: $theme-color--black;
    & a {
        color: $theme-color--default;
        border-bottom: 1px solid #ccc;
        position: relative;
        &::before {
            content: '';
            width: 0;
            height: 1px;
            bottom: -1px;
            position: absolute;
            left: auto;
            right: 0;
            z-index: 1;
            -webkit-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
            transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
            background: currentColor;
        }
        &:hover::before {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}
.img-width {
    width: 100%;
}

.title-dec-text {
    text-align: center;
    max-width: 760px;
    margin: auto;
}

::selection {
    color: $white;
    background-color: $theme-color--default;
}
form {
    input:focus::-webkit-input-placeholder { color:transparent; }
    input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
    input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
    input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    input, textarea{
        &::placeholder{
            transition: $transition--default;
        }
    }
}
input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
    color: $theme-color--default;
    border-color: $theme-color--default;
}
input[type="checkbox"] {
    position: relative;
    background: 0 0;
    border-width: 0;
    box-shadow: none;
    margin: 0 10px 0 3px;
    cursor: pointer;
}

/* swiper default styles */

.swiper-pagination{
  position: static;
  display: block;
  line-height: 1;
  &--vertical{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    bottom: auto;
    left: auto;

    .swiper-pagination-bullet{
      display: block;
      margin-bottom: 10px;
      line-height: 1;
      margin-right: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    position: relative;
    outline: none;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
    &:before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background: #d8d8d8;
      z-index: 1;
      transition: $transition--default;
    }

    &:hover{
      &:before{
        width: 12px;
        height: 12px;
        opacity: 1;
        background: $theme-color--default;
      }
    }
}
    
    
.swiper-pagination-bullet-active{
    &:before{
      width: 12px;
      height: 12px;
      opacity: 1;
      background: $theme-color--default;
    }
  }
}
.site-wrapper-reveal{
    position: relative;
    z-index: 2;
    background: $white;
}

.swiper-pagination.swiper-pagination-white  {
    & .swiper-pagination-bullet {
        margin: 8px;
        &:hover{
            &:before{
                width: 12px;
                height: 12px;
                opacity: 1;
                background: #fff;
            }
        }
    }
    & .swiper-pagination-bullet-active::before {
        opacity: 1;
        background: #fff;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 9px #ededed;
    }
}

.swiper-pagination.swiper-pagination-black  {
    & .swiper-pagination-bullet {
        margin: 8px;
        &:hover{
            &:before{
                width: 12px;
                height: 12px;
                opacity: 1;
                background: #333;
            }
        }
    }
    & .swiper-pagination-bullet-active::before {
        opacity: 1;
        background: #333;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 9px #8b7b7b;
    }
}

.swiper-nav-button {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s, opacity 0.1s linear 2s;
    background-image: none;
    text-align: center;
    user-select: none;
    outline: none;
    width: 48px;
    height: 48px;
    font-size: 24px;
    color: #6d70a6;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -50%);
    margin: 0;
    top: 50%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: inherit;
        color: #fff;
        background: #fff;
    }
}
.swiper-nav-button:hover  {
    color: #fff;
     &::before {
        color: #fff;
        background: $theme-color--default;
    }
}
.swiper-container {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.swiper-container:hover {
    & .swiper-nav-button {
        opacity: 1;
        visibility: visible;
    }
}

.swiper-nav-button i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.swiper-nav-button i::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: $font-awesome;
    font-weight: 600;
    content: '\f104';
}
.swiper-nav-prev i::before {
     content: '\f104';
}
.swiper-button-next i::before {
    content: '\f105';
}
.swiper-wrapper {
    transition-timing-function: linear !important;
}

.single-element-wrap {
    & ul {
        & li {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*===================================
    - Breadvrumb  
=================================*/

.breadcrumb-area {
    padding-top: 114px;
    padding-bottom: 114px;
    background-color: #f6f2ed;
    background-repeat: no-repeat;
    background-position: center center;
    border-bottom-width: 0px;
    padding-top: 136px;
    padding-bottom: 131px;
    background-size: cover;
    z-index: 3;
    position: relative;
    background-image: url(../images/bg/title-bar-01-bg.webp);
    @media #{$large-mobile,$tablet-device,$desktop-device}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.breadcrumb-list {
    margin-top: 20px;
    & li {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1.58;
        text-transform: uppercase;
        & a {
            position: relative;
            &::after {
                content: '';
                width: 0;
                height: 1px;
                bottom: 0;
                position: absolute;
                left: auto;
                right: 0;
                z-index: -1;
                transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                background: currentColor;
            }
            &:hover {
                &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                    z-index: 0;
                }
            }
        }
        &.active {
            color: #6D70A6;
        }
    }
}

.page-pagination {
    & li {
        display: inline-block;
        
        & a {
            font-weight: 500;
            padding: 0 10px;
            display: block;
            text-align: center;
            line-height: 41px;
            min-width: 41px;
            height: 41px;
            text-transform: uppercase;
            color: #ababab;
            letter-spacing: 2px;
            border-radius: 5px;
            &.current {
                background: #f6f5f9;
                color: $theme-color--default;
            }
            &:hover {
                color: $theme-color--default;
            }
        }
    }
}
/*=============================================
=                - Preloader                =
=============================================*/

.preloader-active {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: 99999;
    .preloader-area-wrap {
        background-color: $white;
        position: absolute;
        left: 0;
        display: block;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 999999;
        .spinner {
            div {
                background-color: $theme-color--default;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin-right: 15px;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both;

                &.bounce1 {
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                }

                &.bounce2 {
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;
                }
            }
        }
        @keyframes sk-bouncedelay {
            0%,
            80%,
            100% {
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            40% {
                -webkit-transform: scale(1.0);
                transform: scale(1.0);
            }
        }
    }
}
.preloader-area-wrap {
    display: none;
}
.open_tm_preloader {
    position: fixed;
    background-color: transparent;
    z-index: 9999;
    height: 100%;
    width: 100%;
    -webkit-transition: .2s all ease;
    -o-transition: .2s all ease;
    transition: .2s all ease;
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
    &.loaded {
        opacity: 0;
        visibility: hidden;
        &:before, 
        &:after {
            width: 0%;
        }
    }
    &:before, 
    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 50%;
        background-color: #fff;
        transition: all 0.5s ease-in-out;
    }
    &:before {
        top: 0;
        left: 0;
    }
    &:after {
        bottom: 0;
        right: 0;
    }
}


/*=============================================
   - Scroll top         
=============================================*/

.scroll-top{
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0,0,0,.03);
    display: block;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: $theme-color--default;
    background-size: 200% auto;
    background-position: left center;
    color: $white;
    transition: all .5s cubic-bezier(.645,.045,.355,1);
    overflow: hidden;

    @media #{$extra-small-mobile}{
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }

    &.show{
        visibility: visible;
        opacity: 1;
        bottom: 60px;
    }

    i{
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        transition: $transition--default;
    }

    .arrow-top{
        transform: translate(-50%, -50%);
    }

    .arrow-bottom{
        transform: translate(-50%, 80px);
    }

    &:hover{
        background-position: right center;
        .arrow-top{
            transform: translate(-50%, -80px);
        }
        .arrow-bottom{
            transform: translate(-50%, -50%);
        }
    }
}


/*=============================================
   - Dividers Css        
=============================================*/

.separator-wrap {
    text-align: center;
    display: inline-block;
    margin-top: 40px;
    & .dot {
        border-radius: 50%;
        box-shadow: 0 0 12px #def0ff;
        background: #000;
        background-color: $theme-color--default;
        animation: separator-bounce 1.4s ease-in-out 0s infinite both;
        width: 14px;
        height: 14px;
        &.second-circle {
            margin-top: 18px;
            animation-delay: -.16s;
        }
        &.third-circle {
            margin-top: 14px;
            animation-delay: -.32s;
        }
    }
}
@-webkit-keyframes separator-bounce {
    0%,
    80%,
    100% {
       -webkit-transform: scale(0.5);
       transform: scale(0.5);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

@keyframes separator-bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

/*=================================
=          - Google Map Css       =                
===================================*/
#googleMap-1, #googleMap-2 , #googleMap-3 {
    height: 400px;
}

/*=====  End of Default CSS  ======*/