/*=============================================
=         36. Processing Hero Css             =
=============================================*/
.machine-learning-hero-bg {
    background-image: url("../images/hero/new-slider-bg.webp");
    background-color: #072a83;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 320px ;
    padding-bottom: 200px;
    @media #{$laptop-device} {
        padding-top: 200px;
        padding-bottom: 100px;
    }
    @media #{$desktop-device} {
        padding-top: 150px;
        padding-bottom: 100px;
    }
    @media #{$tablet-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$large-mobile} {
        padding-top: 0px;
        padding-bottom: 80px;
    }
}
.machine-learning-hero-text {
    max-width: 730px;
    width: 100%;
    @media #{$large-mobile}{
        margin-top: 60px;
        text-align: center;
    }
    & h6 {
        line-height: 36px;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    & h1 {
        color: $white;
        font-weight: 500;
        font-size: 68px;
        letter-spacing: 0;
        line-height: 1.2;
        @media #{$laptop-device}{
          font-size: 60px; 
        }
        @media #{$desktop-device}{
          font-size: 46px; 
        }
        @media #{$tablet-device}{
          font-size: 40px;
        }
        @media #{$large-mobile}{
          font-size: 34px;
        }
    }
    & p {
        color: $white;
        line-height: 32px;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 18px;
    }
}


.hero-button {
    display: flex;
    align-items: center;
    @media #{$large-mobile}{
        justify-content: center;
    }
    @media #{$small-mobile}{
        justify-content: center;
        & .btn {
            min-width: 140px;
            padding: 0 24px;
        }
    }
}
.hero-popup-video {
    margin-left: 20px;
    & a {
        display: inline-block;
        transition: $transition--default;
        .video-content {
            display: flex;
            & .video-text {
                margin-left: 8px;
                color: $white;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
    & .video-play {
        font-size: 10px;
        display: inline-block;
        margin: 0;
        border: 1px solid #ddd;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        line-height: 30px;
        color: #fff;
        & .video-play-icon {
            & i {
                color: $white;
            }
        }
    }
}

.hero-brand-wrap {
    margin-top: 150px;
    display: flex;
    @media #{$desktop-device} {
        margin-right: 30px;
        margin-top: 100px;
    }
    @media #{$tablet-device} {
        margin-right: 30px;
        margin-top: 100px;
    }
    @media #{$large-mobile} {
        margin-top: 50px;
        justify-content: center;
    }
    .brand-logo {
        position: relative;
        margin-right: 50px;
        @media #{$desktop-device} {
            margin-right: 30px;
        }
        @media #{$large-mobile,$tablet-device} {
            margin: 0 10px;
        }
        &__image {
            transition: all .4s linear;
        }
        &__image-hover {
            visibility: hidden;
            opacity: 0;
            transform: scale(.8,.8);
            transition: all .4s linear;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &:hover{
            .brand-logo__image {
                visibility: hidden;
                opacity: 0;
                transform: scale(.8,.8);
            }
            .brand-logo__image-hover {
                opacity: 1;
                visibility: visible;
                transform: none !important;
                z-index: 3;
            }
        }
    }
}


.machine-learning-about-bg {
    background-color: #f8fbfe;
    background-image: url("../images/bg/n-1-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
.machine-learning-service-bg {
    background-color: #f8f8f8;
    background-image: url("../images/bg/n-2-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
.machine-learning-mark-text {
    max-width: 370px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.67;
    color: #696969;
    padding-left: 16px;
    border-left: 2px solid #086ad8;
}
.machine-learning-about-content {
    .dec-text {
        font-size: 18px;
    }
}

.machine-learning-contact-us-bg {
    background-image: url("../images/bg/new-cta-bg.webp");
    background-color: $theme-color--default;
    background-size: cover;
    background-repeat: no-repeat;
}

.machine-learning-project-bg {
    background-color: #f5f5f5;
    background-image: url("../images/bg/n-3-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
.testimonials-contails-machine {
    h6 {
        font-size: 14px;
        font-weight: 700;
        line-height: 2;
        letter-spacing: 3px;
        color: #ababab;
    }
    p {
        font-size: 36px;
        font-weight: 500;
        line-height: 1.22;
        color: #002fa6;
        margin-top: 20px;
        margin-bottom: 24px;
    }
    .testimonial-info {
        display: flex;
        flex-wrap: wrap;
        .testimonial-name {
            font-size: 15px;
            font-weight: 700;
            color: #454545;
            margin-top: 5px;
            margin-right: 10px;
            position: relative;
            &::after {
                content: ' / ';
                left: 0;
            }
        }
        .testimonial-by-line {
            font-size: 14px;
            font-weight: 400;
            line-height: 2.29;
            color: #7e7e7e;
        }
    }
}
.swiper-pagination-machine {
    position: absolute;
    width: auto !important;
    margin-bottom: 50px;
}